<template>
  <div id="subscribe" class="flex flex-col gap-4">
    <form
      action="https://slapstar.us20.list-manage.com/subscribe/post?u=40135ad7fb7b413fd2254ee6e&amp;id=71ea26809e&amp;f_id=006a79e0f0"
      method="post"
      target="_self"
      class="w-[752px] max-md:w-full h-[72px] pl-6 pr-1 py-1 bg-white/10 rounded-[38px] flex justify-end items-center gap-2.5"
    >
      <input
        type="email"
        name="EMAIL"
        placeholder="Enter your email"
        class="h-16 grow text-white text-xl bg-transparent outline-none"
        required
      />
      <div aria-hidden="true" style="position: absolute; left: -5000px">
        <input
          type="text"
          name="b_40135ad7fb7b413fd2254ee6e_71ea26809e"
          tabindex="-1"
          value=""
        />
        <input type="hidden" name="redirect" tabindex="-1" value="" />
        <input type="hidden" name="mc_optin" tabindex="-1" value="0" />
      </div>
      <button
        type="submit"
        name="subscribe"
        class="w-60 h-16 bg-gradient-to-tr from-[#dd0a6f] to-[#ff5b70] rounded-[180px] flex justify-center items-center max-md:w-16"
      >
        <span class="text-white text-xl font-semibold max-md:hidden">
          Subscribe
        </span>
        <img
          src="/icons/telegram.svg"
          alt="Telegram Icon"
          class="w-6 h-6 hidden max-md:inline"
        />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionForm',
}
</script>
