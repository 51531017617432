<template>
  <div
    class="grid grid-cols-4 max-md:grid-cols-2 gap-5 justify-between items-center mt-10 px-4 w-full"
  >
    <div
      v-for="feature in features"
      :key="feature.title"
      class="flex flex-col self-stretch my-auto gap-3 overflow-hidden"
    >
      <img
        :src="feature.icon"
        :alt="feature.alt"
        loading="lazy"
        class="object-contain self-center w-14 aspect-square"
      />
      <h5 class="text-2xl max-md:text-lg font-semibold text-white">
        {{ feature.title }}
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { GameplayFeature } from '../types'

export default defineComponent({
  name: 'GameplayFeatures',
  setup() {
    const features: GameplayFeature[] = [
      {
        icon: '/icons/icon-collection.svg',
        title: 'NFT collections',
        alt: 'NFT collections icon',
      },
      {
        icon: '/icons/icon-hand.svg',
        title: 'Slapping Mechanics',
        alt: 'Slapping mechanics icon',
      },
      {
        icon: '/icons/icon-present.svg',
        title: 'Loot Boxes',
        alt: 'Loot boxes icon',
      },
      {
        icon: '/icons/icon-coctail.svg',
        title: 'Gifts & Presents',
        alt: 'Gifts and presents icon',
      },
    ]

    return {
      features,
    }
  },
})
</script>
