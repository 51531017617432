import { SocialIcons } from '../types'

export const tgAppLink = 'https://t.me/SlapStarBot/app'
export const socials: SocialIcons[] = [
  {
    name: 'Instagram',
    src: '/icons/instagram.svg',
    link: 'https://instagram.com/slapstarx',
  },
  {
    name: 'X (Twitter)',
    src: '/icons/twitter.svg',
    link: 'https://x.com/slapstarx',
  },
  {
    name: 'Telegram',
    src: '/icons/telegram.svg',
    link: 'https://t.me/SlapStarX',
  },
  {
    name: 'TikTok',
    src: '/icons/tiktok.svg',
    link: 'https://www.tiktok.com/@slapstarx',
  },
  {
    name: 'Youtube',
    src: '/icons/youtube.svg',
    link: 'https://www.youtube.com/@slapstarx',
  },
]
